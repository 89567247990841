<!-- 首页 -->
<template>
  <div class="email-setting-page" v-if="!isUserInfo">
    <van-form ref="ruleForm" class="form" @failed="onFailed">
      <van-field
        label="姓名"
        v-model="ruleForm.userName"
        disabled
        name="userName"
        size="large"
      />
      <van-field
        label="联系电话"
        v-model="ruleForm.phoneNumber"
        disabled
        name="phoneNumber"
        size="large"
      />
      <van-field
        label="邮箱"
        v-model="ruleForm.email"
        placeholder=""
        name="email"
        :rules="[
          { required: true, message: '请输入邮箱' },
          { pattern, message: '邮箱格式不正确' },
        ]"
        size="large"
        maxlength="64"
      />
      <van-field
        type="password"
        label="邮箱密码"
        v-model="ruleForm.password"
        placeholder=""
        name="password"
        :rules="[{ required: true, message: '请输入邮箱密码' }]"
        size="large"
        maxlength="64"
      />
    </van-form>
    <div class="footer-buttons">
      <van-button class="pass" @click="validKeep">验证并保存</van-button>
      <van-button class="reject-btn" @click="goBack">取消</van-button>
    </div>
  </div>
  <div class="email-setting-page" v-else>
    <van-cell class="email-avatar" @click="changeUser">
      <p class="email-name">
        {{ myEmailInfo.email }} <van-icon name="edit" class="link" />
      </p>
      <van-image
        class="image"
        round
        width=".5rem"
        height=".5rem"
        :src="myEmailInfo.avaterUrl"
      />
    </van-cell>
    <van-cell-group>
      <van-cell title="我的姓名" :value="myEmailInfo.staffName" is-link />
      <van-cell title="我的电话" :value="myEmailInfo.userPhone" is-link />
      <van-cell title="我的职务" :value="myEmailInfo.staffDuty" is-link />
      <van-cell
        title="我的组织架构"
        :value="myEmailInfo.departmentName"
        is-link
      />
    </van-cell-group>
  </div>
</template>

<script>
import { setEmail, getMyEmailInfo } from "@/api/email";
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      ruleForm: {
        userName: "",
        email: "",
        password: "",
        phoneNumber: "",
      },
      rules: {
        email: [{ required: true, message: "请输入邮件名", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      isUserInfo: false, //邮箱是否同步
      // pattern:
      ///^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      pattern:
        /^([a-zA-Z0-9_\.\-])+\@((zzyjrlzyglyxgs.com)|(yun-g.com)|(aicthink.com))$/,
    };
  },

  components: {},

  computed: {
    ...mapState("email", ["myEmailInfo"]),
  },

  mounted() {
    this.getUserInfo();
    this.emailInfo();
  },

  methods: {
    ...mapActions("login", ["getInfo"]),
    ...mapActions("email", ["getEmailInfo"]),

    getUserInfo() {
      this.getInfo().then((res) => {
        if (res) {
          let { data, code } = res;
          if (code == 200) {
            this.ruleForm.userName = data.userName;
            this.ruleForm.phoneNumber = data.phoneNumber;
          }
        }
      });
    },
    emailInfo() {
      this.getEmailInfo().then((res) => {
        if (res) {
          this.loading = false;
          let { data, success } = res;
          if (success) {
            if (data) {
              this.isUserInfo = true;
              this.ruleForm.email = data.email;
              this.ruleForm.password = data.password;
            }
          }
        }
      });
    },
    onFailed(e) {},
    validKeep() {
      this.$refs["ruleForm"].validate(["email", "password"]).then((valid) => {
        setEmail({
          email: this.ruleForm.email,
          password: this.ruleForm.password,
        }).then((res) => {
          if (res) {
            let { success, msg } = res;
            if (success) {
              this.$message.success(msg);
              this.getUserInfo();
              this.emailInfo();
            }
          }
        });
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    changeUser() {
      this.isUserInfo = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.email-setting-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .form {
    flex: 1;
    overflow-y: auto;
    ::v-deep .van-cell {
      display: flex;
      flex-direction: column;

      .van-field__label {
        width: 100%;
        color: #000;
        background: #f0f6ff;
        padding: 0.1rem;
        margin-bottom: 0.1rem;
      }
      .van-cell__value {
        background: #fff;
      }
    }
  }

  .footer-buttons {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-right: 0.3rem;
    width: 7.5rem;
    height: 1.04rem;
    background: #ffffff;
    box-shadow: 0 0.06rem 0.3rem 0 rgba(0, 0, 0, 0.1);
    align-items: center;
    .pass {
      width: 3.16rem;
      height: 0.8rem;
      background: #0094ee;
      border-radius: 0.4rem 0 0 0.4rem;
      font-size: 0.26rem;
      color: #ffffff;
      line-height: 0.37rem;
    }
    .reject-btn {
      width: 3.16rem;
      height: 0.8rem;
      background: #e5f4fd;
      border-radius: 0 0.4rem 0.4rem 0;
      font-size: 0.26rem;
      color: #ffffff;
      line-height: 0.37rem;
      font-size: 0.26rem;
      color: #0094ee;
      line-height: 0.37rem;
    }
  }
  .email-avatar {
    ::v-deep .van-cell__value {
      display: flex;
      flex-direction: row;
    }
    .email-name {
      flex: 1;
      text-align: center;
    }
    .image {
      width: 0.5rem;
      height: 0.5rem;
    }
    .link {
      color: #0094ee;
    }
  }
}
</style>
